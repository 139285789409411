import posthog from 'posthog-js'

class AnalyticsService {
  events = {
    signUpOpened: 'Sign up opened',
    signUpSubmitted: 'Sign up submitted',
    signUpComplete: 'Sign up complete',
    signUpFailed: 'Sign up failed',
    signInOpened: 'Sign in opened',

    trackingRejected: 'Tracking rejected',


    personaliseCardAddText_Opened: 'Card customise text started',
    personaliseCardAddText_Complete: 'Card customise text completed',
    personaliseCardAddText_Font_Opened: 'Card customise fonts opened',
    personaliseCardAddText_Font_Selected: 'Card customise fonts selected',
    personaliseCardAddText_Color_Opened: 'Card customise colors opened',
    personaliseCardAddText_Color_Selected: 'Card customise colors selected',

    personaliseCard_addRecipientStarted: 'Card customise recipients started',
    personaliseCard_addRecipientCompleted: 'Card customise recipients completed',

  }

  initialise() {
    posthog.init(process.env.REACT_APP_POSTHOG_TOKEN,
        {
          api_host: 'https://eu.i.posthog.com',
          person_profiles: 'identified_only' // or 'always' to create profiles for anonymous users as well
        }
    )
  }

  sendEvent(eventName, data) {
    posthog.capture(eventName, data)
  }

  disableTracking() {
    window.localStorage.setItem('plausible_ignore', true)
  }

  enableTracking() {
    window.localStorage.setItem('plausible_ignore', false)
  }

}

export default new AnalyticsService();