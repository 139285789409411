import { createSlice } from '@reduxjs/toolkit'
import type { Card } from '../models'
import ApiService from '../services/ApiService';
import {store_static_doFetchStaticData} from '../store/staticDataSlice';
import analyticsService from '../services/AnalyticsService';

const apiService = new ApiService();

export const authSlice = createSlice({
  name: 'counter',
  initialState: {
    currentUser: null,
    signInIsLoading: false,
    signInError: null,
  },
  reducers: {
    store_auth_setCurrentUser: (state, action) => {
      state.currentUser = action?.payload
      state.signInIsLoading = false
    },
    store_auth_signOutComplete: (state, action) => {
      state.signInIsLoading = false
      state.currentUser = null
    },
    store_auth_signInLoading: (state, action) => {
      // state.currentUser = null
      state.signInIsLoading = true
      state.signInError = null
    },
    store_auth_signInComplete: (state, action) => {
      state.signInIsLoading = false
      state.currentUser = action.payload
    },
    store_auth_signInFailure: (state, action) => {
      state.signInIsLoading = false
      state.signInError = action.payload
    },
  },
})

export const store_auth_doRefreshUser = (forceRefresh = false) => async dispatch => {
  apiService.refreshToken(forceRefresh)
      .then(response => {
        setTimeout(() => {
          dispatch(store_auth_setCurrentUser(response.result?.user))
          dispatch(store_static_doFetchStaticData())
        }, 1000)
      })
}

export const store_auth_doSignInUser = (username, password, confirmPassword, isCreator) => async dispatch => {
  dispatch(store_auth_signInLoading())
  apiService.postSignIn(username, password, confirmPassword, isCreator)
      .then(response => {
        dispatch(store_auth_signInComplete(response.result?.user))
        analyticsService.sendEvent(analyticsService.events.signUpComplete)
      })
      .catch(err => {
        dispatch(store_auth_signInFailure(err.message))
        analyticsService.sendEvent(analyticsService.events.signUpFailed, {
          error: err
        })
      })
}

export const store_auth_doSignOutUser = (username, password, confirmPassword) => async dispatch => {
  dispatch(store_auth_signInLoading())
  // Cannot delete cookies as they are http only for security
  apiService.postSignOut()
      .then(response => {
        dispatch(store_auth_signInComplete(response.result?.user))
      })
      .catch(err => {
        dispatch(store_auth_signInFailure(err.message))
        dispatch(store_auth_doRefreshUser())
      })
}

// Action creators are generated for each case reducer function
export const {
  store_auth_setCurrentUser,
  store_signOut,
  store_auth_signInLoading,
  store_auth_signInComplete,
  store_auth_signInFailure,
  store_auth_signOutComplete,
} = authSlice.actions

export const store_auth_isSignedIn = (state) => state.authSlice.currentUser?.email?.length > 1
export const store_auth_isAuthenticated = (state) => state.authSlice.currentUser?.id != null
export const store_auth_isAdmin = (state) => state.authSlice.currentUser?.isAdmin
export const store_auth_getCurrentUser = (state) => state.authSlice.currentUser
export const store_auth_isSignInLoading = (state) => state.authSlice.signInIsLoading
export const store_auth_getSignInError = (state) => state.authSlice.signInError

export default authSlice.reducer