import React, { lazy, StrictMode, Suspense } from 'react'
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import './index.css';
// import App from './App';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import {reduxStore, reduxPersistor} from './store/store'
import { ChakraProvider, extendTheme, Spinner } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'
import {PersistGate} from "redux-persist/integration/react";
import App from './App';

const theme = extendTheme({
  config: {
    useSystemColorMode: false,
    initialColorMode: 'light',
  },
  fonts: {
    heading: 'Poppins',
    body: 'Poppins',
    div: 'Poppins',
  },
  colors: {
    primary: {
      '50': '#f1f9fe',
      '100': '#e1f2fd',
      '200': '#bde6fa',
      '300': '#82d2f7',
      '400': '#3fbcf1',
      '500': '#20ade9',
      '600': '#0984c0',
      '700': '#09699b',
      '800': '#0c5980',
      '900': '#104a6a',
      '950': '#0a3047',
      // DEFAULT: '#0089FA',
      // 50: '#B3DCFF',
      // 100: '#9ED3FF',
      // 200: '#75C1FF',
      // 300: '#4DAEFF',
      // 400: '#249CFF',
      // 500: '#0089FA',
      // 600: '#006AC2',
      // 700: '#004C8A',
      // 800: '#002D52',
      // 900: '#000E1A',
      // 950: '#000000'
    },
    accent: {
      '50': '#f3f2ff',
      '100': '#ebe7ff',
      '200': '#d9d3ff',
      '300': '#bdafff',
      '400': '#9d81ff',
      '500': '#7f4fff',
      '600': '#6f2bfc',
      '700': '#6620e9',
      '800': '#5114c3',
      '900': '#44139f',
      '950': '#28096c',
      // DEFAULT: '#FA0089',
      // 50: '#FFB3DC',
      // 100: '#FF9ED3',
      // 200: '#FF75C1',
      // 300: '#FF4DAE',
      // 400: '#FF249C',
      // 500: '#FA0089',
      // 600: '#C2006A',
      // 700: '#8A004C',
      // 800: '#52002D',
      // 900: '#1A000E',
      // 950: '#000000'
    },
  },
  styles: {
    global: (props) => ({
      body: {
        bg: mode("#ffffff", "#121212")(props),
        color: 'gray.700',
      },
      h1: {
        color: 'gray.700',
      },
      h2: {
        color: 'gray.700',
      },
      h3: {
        color: 'gray.700',
      },
    })
  }
});

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <StrictMode>
      <ChakraProvider theme={theme}>
        <BrowserRouter>
          <Provider store={reduxStore}>
            <PersistGate loading={null} persistor={reduxPersistor}>
              <App/>
            </PersistGate>
          </Provider>
        </BrowserRouter>
      </ChakraProvider>
    </StrictMode>
);


//giffy api token: 2VmiURjfP8tjKydnnsg0GSCnjI710O3B

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
