import {useState} from 'react';
import {Box} from '@chakra-ui/react';

const SkeletonBox = (props) => {
  const [bgColor, setBgColor] = useState('gray.300')

  return (
      <Box
          bg={bgColor}
          {...props}
      />
  )
}

export default SkeletonBox