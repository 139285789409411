import React, {lazy, Suspense, useEffect} from 'react';
import { Stack } from '@chakra-ui/react'
import AppRoutesSkeletonLoader from './AppRoutesSkeletonLoader';
import './App.css'
import analyticsService from './services/AnalyticsService';

const AppProvider = lazy(() => import('./providers/AppProvider'))
const AppRoutes = lazy(() => import('./AppRoutes'))

function App () {
  useEffect(() => {
    analyticsService.initialise()
  }, [])

  return (
    <Suspense>
      <Suspense>
        <AppProvider>
          <Stack
              id={'app-stack'} spacing={0}
              height={'100%'} width={'100%'}
              alignItems={'center'}
          >
            <Suspense>
              <AppRoutes />
            </Suspense>
          </Stack>

          {/*<LoginPopUp/>*/}
          {/*<AuthSessionEndedModal/>*/}
        </AppProvider>
      </Suspense>
    </Suspense>
  )
}

export default App
