import { createSlice } from '@reduxjs/toolkit'
import ApiService from '../services/ApiService'

export const staticSlice = createSlice({
    name: 'static',
    initialState: {
        cardGroups: [],
        cardGroupsLoading: false,
        isStaticDataLoading: false,
        cardDefaultFont: null,
        featuredFonts: [],
    },
    reducers: {
        updateCardGroupsLoading: (state, action) => {
            state.cardGroupsLoading = action.payload
        },
        updateCardGroups: (state, action) => {
            state.cardGroups = action.payload
        },
        store_static_staticDataLoading: (state, action) => {
            state.isStaticDataLoading = true
        },
        store_static_setStaticData: (state, action) => {
            state.isStaticDataLoading = false
            const payload = action.payload
            state.cardGroups = payload?.cardGroups
            state.cardDefaultFont = payload?.cardDefaultFont
            state.cardMessageDefaultFont = payload?.cardMessageDefaultFont
            state.featuredFonts = payload?.featuredFonts
        },
    }
})

export const store_static_doFetchStaticData = () => async dispatch => {
    dispatch(store_static_staticDataLoading())
    try {
        const response = await new ApiService().getStaticData()
        dispatch(store_static_setStaticData(response.result))
    } catch (err) {
        dispatch(store_static_setStaticData([]))
    }
}

export const store_static_getCardGroups = (state) => state.staticDataSlice.cardGroups;
export const store_static_getCardDefaultFont = (state) => state.staticDataSlice.cardDefaultFont;
export const store_static_getCardMessageDefaultFont = (state) => state.staticDataSlice.cardMessageDefaultFont;
export const store_static_getFeaturedFonts = (state) => state.staticDataSlice.featuredFonts;

// Action creators are generated for each case reducer function
export const {
    updateCardGroups,
    store_static_staticDataLoading,
    store_static_setStaticData
} = staticSlice.actions

export default staticSlice.reducer