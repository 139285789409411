import {v4} from 'uuid';
import PeacoloAFrameCustomEntityObject from './models/PeacoloAFrameCustomEntityObject';

export default class PeacoloCustomEntityObjectMapper {
  
  convertPeacoloCustomEntityObjectToAFrameEntityProps(peacoloAFrameCustomEntityObject) {
    console.log('convertPeacoloCustomEntityObjectToAFrameEntityProps', peacoloAFrameCustomEntityObject)
    if (peacoloAFrameCustomEntityObject == null) {
      peacoloAFrameCustomEntityObject = new PeacoloAFrameCustomEntityObject()
    }

    const id = peacoloAFrameCustomEntityObject.id
    let vrOptions = {
      id: peacoloAFrameCustomEntityObject.id
    }

    if (peacoloAFrameCustomEntityObject.type === "text") {
      let position = peacoloAFrameCustomEntityObject.entity_position ? peacoloAFrameCustomEntityObject.entity_position : []
      const textValue = peacoloAFrameCustomEntityObject?.text_value?.length > 0 ? peacoloAFrameCustomEntityObject?.text_value : 'Enter text here...'
      vrOptions = {
        // ...vrOptions,
        position: Array.from([...position]),
        // position: [0,0, peacoloAFrameCustomEntityObject.positionZ ?? -1.5],
        rotation: Array.from(peacoloAFrameCustomEntityObject.entity_rotation),
        // rotation: [0,0,0],
        text: {
          value: textValue,
          color: peacoloAFrameCustomEntityObject.text_color ?? 'white',
          align: peacoloAFrameCustomEntityObject.text_textAlign ?? 'center',
          font: peacoloAFrameCustomEntityObject.text_font?.length > 1 ? peacoloAFrameCustomEntityObject.text_font : 'roboto',
          shader: 'msdf',
          // width: peacoloAFrameCustomEntityObject.text_size ?? 8,
          width: peacoloAFrameCustomEntityObject.text_width ?? 8,
          wrapCount: peacoloAFrameCustomEntityObject.text_wrap ?? 30
        },
        geometry: {
          primitive: 'box',
          width: 0,
          height: 0,
          depth: 0.001
        },
        material: {
          color: peacoloAFrameCustomEntityObject.text_backgroundColor ?? 'white',
          opacity: peacoloAFrameCustomEntityObject.text_backgroundOpacity ?? 1,
          shader: peacoloAFrameCustomEntityObject.text_backgroundShader ?? 'flat',
          side: 'double',
        }
      }
    } else if (peacoloAFrameCustomEntityObject.type == "image") {
      vrOptions = {
        ...vrOptions,
        // color: peacoloAFrameCustomEntityObject.color,
        // src: peacoloAFrameCustomEntityObject.src,
        // height: peacoloAFrameCustomEntityObject.height,
        // width: peacoloAFrameCustomEntityObject.width,
        // imageData: peacoloAFrameCustomEntityObject.imageData,
        // fileName: peacoloAFrameCustomEntityObject.fileName,
        // position: [0,0, peacoloAFrameCustomEntityObject.positionZ ?? -1.5],
      }
    }


    return {
      id: id,
      ...vrOptions
    }
  }
  
  convertAFrameEntityPropsToPeacoloCustomEntityObject(vrOptions = { }) {
    console.debug(`covertToPersonaliseOptions: ${JSON.stringify(vrOptions)}`)

    if (vrOptions.text) {
      return new PeacoloAFrameCustomEntityObject({
        id: vrOptions?.id ?? `customEntity_${v4()}`,
        text_color: vrOptions?.text?.color ?? "white",
        text_font: vrOptions?.text?.font ?? "",
        text_value: vrOptions?.text?.value ?? "Enter text here...",
        text_width: vrOptions?.geometry?.width,
        text_backgroundColor: vrOptions?.material?.color ?? "rgba(0,0,0,0)",
        text_backgroundOpacity: vrOptions?.material?.opacity ?? 0,
        text_backgroundShader: vrOptions?.material?.shader ?? 'flat',
        text_textAlign: "center",
        entity_positionZ: vrOptions?.position[2],
        entity_position: vrOptions?.position,
        entity_rotation: vrOptions?.rotation,
      })
    } else if (vrOptions.imageData) {
      // return new PersonaliseOptionsImage({
      //   id: vrOptions?.id ?? `customEntity_${v4()}`,
      //   src: vrOptions?.src ?? "",
      //   imageData: vrOptions?.imageData ?? "",
      //   fileName: vrOptions?.fileName ?? "",
      //   height: vrOptions.height,
      //   width: vrOptions.width,
      //   positionZ: vrOptions?.position?.length > 2 ? vrOptions?.position[2] : 1
      // })
      return {}
    } else {
      return {}
    }
  }
}